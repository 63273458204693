@media print {
  @page {
    margin: 0px;
    padding: 0px;
  }
  .etiqueta2 {
    display: block;
  }

  .App {
    visibility: none;
    display: none;
  }

  #printable,
  #printable * {
    visibility: visible;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: red;
  }
  #printable {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #sua_div {
    width: 8cm;
    height: 4cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .nomeCredencial {
    font-size: 0.72cm;
    font-weight: 800;
    text-transform: uppercase;
  }
  .demaisCredencial {
    font-size: 0.4cm;
    text-transform: uppercase;
  }
  .demaisCredencial2 {
    margin-top: 0.1cm;
    font-size: 0.4cm;
    text-transform: uppercase;
  }

  /* Estilo Etiqueta Sorteio  */
  .etiqueta_sorteio {
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 0;
    left: 0;

    border: 0px solid rgba(0, 0, 0, 0);
    color: #000 !important;
    font-family: 'Times New Roman', Times, serif !important;

    width: 8cm !important;
    height: 5cm !important;
    display: flex;
    flex-direction: column;
  }

  .cod_inscricao {
    border-bottom: 1px dashed rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5cm;
    font-family: 'Hypermarket' !important;
    margin-top: 0.1cm;
  }

  .dadosSorteioTicket {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dadosSorteioTicket h2 {
    font-weight: 800;
    font-size: 0.8cm;
    text-transform: uppercase;
    font-family: 'Hypermarket' !important;
  }

  .dadosSorteioTicket h3 {
    margin-top: -0.1cm;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.4cm;
    font-family: 'Hypermarket' !important;
  }

  .dadosSorteioTicket h4 {
    font-weight: 800;
    font-size: 0.4cm;
    text-transform: uppercase;

    display: flex !important;
    justify-content: center;
    align-items: center;
    font-family: 'Hypermarket' !important;
  }

  .dadosSorteioTicket h4 svg {
    font-size: 1rem;
  }

  .descricaoSorteio {
    border-top: 1px dashed rgba(0, 0, 0, 1);
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Hypermarket' !important;
    margin-bottom: 0.1cm;
  }

  .descricaoSorteio svg {
    font-size: 1.3rem;
    transform: rotate(00deg);
  }
}

.etiqueta2 {
  display: none;
}

.code {
  margin: 0;
  font: 14px "Fira Sans", sans-serif;
  letter-spacing: 0.01071em;
  font-weight: 400;
  text-transform: uppercase;
}
.typeInscription {
  font-size: 0.6cm;
  font-weight: 600;
  text-transform: uppercase;
}

/* Estilo Etiqueta Sorteio  */
.etiqueta_sorteio {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Fira Sans', sans-serif !important;
  font-size: medium;
}

.App {
  /* display:none; */
}
* {
  scrollbar-width: thin;
  scrollbar-color: #afb2b1 white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #afb2b1;
  border-radius: 20px;
  border: 2px solid white;
}

:root {
  --white: #fff;
  --black: #000;

  /*
  Azul escuro -> #152A70
  Azul -> #1C4185
  Azul claro -> #245C9D
  Ciano -> #7BE7FF */

  --primary: #245c9d;
  --primary-v2: #1c4185;
  --primary-v3: #152a70;
  /* --primary: #f0f; */

  /* gba */
  --primary-rgb: 36, 92, 157;
  --primary-v2-rgb: 28, 65, 133;
  --primary-v3-rgb: 21, 42, 112;

  --gray-50: #f7f8fa;
  --gray-100: #e6e8eb;
  --gray-200: #afb2b1;
  --gray-500: #808080;
  --gray-800: #494d4b;

  --yellow: #f2b705;
  --green: #114b50;

  --purple-site: #5467b0;
  --purple-300: #9f75ff;
  --purple-400: #9164fa;
  --purple-500: #8257e5;
  --purple-800: #6f48c9;

  --success: #07bc0c;
  --info: #3498db;
  --error: #e74c3c;

  --naples_yellow: #f9db6d;
  --brown: brown;
}

@media (max-width: 1366px) {
  html {
    font-size: 80%;
  }
}

/* @media (max-width: 1080px) {
  html {
    font-size: 80%;
  }
} */

/* @media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
} */

body,
input,
textarea,
button {
  font: 500 1rem Inter, sans-serif;
  /* color: var(--gray-500); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: Lexend, sans-serif;
  /* color: var(--gray-800); */
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

button {
  cursor: pointer;
}
